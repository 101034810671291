<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import AccountService from "@/services/AccountService";
import ClientService from "@/services/ClientService";
import MovementService from "@/services/MovementService";
import OrdenService from "@/services/OrdenService";
import HelperJS from "@/functions/general";
//import moment from "moment";


const movementService = new MovementService();
const clientService = new ClientService();
const accountService = new AccountService();
const ordenService = new OrdenService();

const helper = new  HelperJS();

/**
 * Invoice Detail component
 */
export default {
  page: {
    title: "Información de la cuenta",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Información de la cuenta",
       infoPage:{
         sumaIntereses: 0,
        status: ['En curso', 'Por cobrar', 'Cobrado'],
        method_payments: [
          {
            id:1,
            text: 'Efectivo'
          },
          {
            id:2,
            text: 'Transferencia' 
          },
          {
            id:3,
            text: 'Cheque' 
          },
        ],
        view: 'detail',
        typeMovement: ['Retiro', 'Deposito']
      },
      client:{

      },
      amount_saldo: 0,
      account:{
        name: null,
        start_date: null,
        currency: 'MXN',
        interest: null,
        cut_date: null,
        amount: null,
        latest_retirement: null,
        id: null,
        client_id: null,
      },
      movements:[

      ],   
      ordenes:[

      ],
      items: [
        {
          text: "Inicio",
        },
        {
          text: "Detalles de la cuenta",
          active: true,
        },
      ],
    };
  },
  
  created(){

    this.chargeItems();
  },
    methods: {    
    formatMoney(value){
      return helper.formatMoney(value);

    },
    formatDate(value, type='short'){
      return helper.formatDate(value, type);

    },
    typePayment(value){
      let returnFinded = this.infoPage.method_payments.find(item=>item.id===value);
      return returnFinded.text;
    },
    typeMovement(value){
      return this.infoPage.typeMovement[value];
    },
    translateStatus(status){
      return this.infoPage.status[status];
    },
  bgStatus(status){
     let bgs_back = ['bg-soft-warning','bg-soft-success','bg-soft-info'];
      return bgs_back[status];
    },
    chargeItems(){
      let id =  this.$route.params.id;
        accountService.getById(id).then(response=>{
          this.account = response.data.account;

          clientService.getById(response.data.account.client_id).then(response=>{
            this.client = response.data.client;
          });
          let params = {
            account_id: id
          }
           ordenService.getList(params).then(response=>{
            this.ordenes = response.data.ordenes;


            this.infoPage.sumaIntereses = response.data.ordenes.map(item => item.amount).reduce((prev, curr) => prev + curr, 0);
          });
          
        });


        let params = {
          account_id: id
        }
          movementService.getList(params).then(response=>{
          let movements = response.data.movements;
          
          this.movements = movements;
        });

    },
    routeGo(textRoute){
      this.$router.push({
        path: textRoute,
      });
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="invoice-title">
              <h4 class="float-end font-size-16">
                {{account.name}}
                <span class="badge bg-success font-size-12 ms-2">{{account.currency}}</span>
              </h4>
              <div class="mb-4">
                <!--<img
                  src="@/assets/images/logo-dark.png"
                  alt="logo"
                  height="20"
                />-->
              </div>
              <div class="text-muted">
                <p class="mb-1">{{client.full_name}}</p>
                <p class="mb-1">
                  <i class="uil uil-envelope-alt me-1"></i> {{client.email}}
                </p>
                <p><i class="uil uil-phone me-1"></i> {{client.celphone}}</p>
              </div>
            </div>

            <hr class="my-4" />

            <div class="row">
              <div class="col-sm-4">
                 <div class="text-muted text-sm-end">
                  <div>
                    <h5 class="font-size-16 mb-1">Fecha de inicio:</h5>
                    <p>{{formatDate(account.start_date, 'complete')}}</p>
                  </div>
                  <div  class="mt-4">
                    <h5 class="font-size-16 mb-1">Dia de corte:</h5>
                    <p>{{account.cut_date}}</p>
                  </div>
                  
            
                </div>
              </div>
              <div class="col-sm-4">
                <div class="text-muted text-sm-end">
                  <div>
                    <h5 class="font-size-16 mb-1">Interés %:</h5>
                    <p>{{account.interest}}</p>
                  </div>
                  
                  <div class="mt-4">
                    <h5 class="font-size-16 mb-1">Moneda:</h5>
                    <p>{{account.currency}}</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="text-muted text-sm-end">
                  <div>
                    <h5 class="font-size-16 mb-1">Último pago:</h5>
                    <p>{{account.latest_retirement}}</p>
                  </div>
                  
                  <div class="mt-4">
                    <h5 class="font-size-16 mb-1">Próximo pago:</h5>
                    <p>{{account.latest_retirement}}</p>
                  </div>
                </div>
              </div>
            </div>


            <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark collapsed"
              data-toggle="collapse"
              aria-expanded="false"
              aria-controls="addproduct-img-collapse"
              v-b-toggle.accordion-2
            >
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        01
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">Movimientos</h5>
                    <p class="text-muted text-truncate mb-0">
                      Depositos y retiros
                    </p>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse
              id="accordion-2"
              accordion="my-accordion"
              data-parent="#addproduct-accordion"
            >
            <div class="p-4 border-top">
           
               <div class="table-responsive">
                <table class="table table-nowrap table-centered mb-0">
                  <thead>
                    <tr>
                      <th style="width: 70px">No.</th>
                      <th>Fecha</th>
                      <th>Moneda</th>
                      <th>Tipo</th>
                      <th>Forma de pago</th>
                      <th class="text-end" style="width: 120px">Monto</th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr v-for="(movement, idx) in movements" :key="idx">
                      <th scope="row">{{idx+1}}</th>
                      <td>
                         {{formatDate(movement.date)}}
                      </td>
                      <td>
                         {{movement.currency}}
                      </td>
                      <td>
                         {{movement.type===1?'Deposito':'Retiro'}}
                      </td>
                      <td>
                         {{typePayment(movement.method_payment)}}
                      </td>
                      <td class="text-end">
                         {{formatMoney(movement.amount)}}
                      </td>
                    </tr>

                   
                    <tr>
                      <th scope="row" colspan="5" class="border-0 text-end">
                        Total ({{account.currency}})
                      </th>
                      <td class="border-0 text-end">
                        <h4 class="m-0">{{formatMoney(account.amount)}}</h4>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            
            </div>
            </b-collapse>
          </div>

          <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark collapsed"
              v-b-toggle.accordion-3
            >
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        02
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">Historial de pagos</h5>
                    <p class="text-muted text-truncate mb-0">
                     Interés mensual
                    </p>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse
              id="accordion-3"
              accordion="my-accordion"
              data-parent="#addproduct-accordion"
            >
              <div class="p-4 border-top">
                 <div class="table-responsive">
                <table class="table table-nowrap table-centered mb-0">
                  <thead>
                    <tr>
                      <th style="width: 70px">No.</th>
                      <th>Fecha inicio</th>
                      <th>Fecha de corte</th>
                      <th>Estado</th>
                      <th class="text-end" style="width: 120px">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                   <tr v-for="(orden, idx) in ordenes" :key="idx">
                      <th scope="row">{{idx+1}}</th>
                      <td>
                         {{formatDate(orden.start_date)}}
                      </td>
                      <td>
                         {{formatDate(orden.cut_date)}}
                      </td>
                      <td>
                        <div class="badge rounded-pill  font-size-12" :class="bgStatus(orden.status)" >
                          {{ translateStatus(orden.status) }}
                        </div>
                      </td>
                      <td class="text-end">
                         {{formatMoney(orden.amount)}}
                      </td>
                    </tr>
                     
                    <tr>
                      <th scope="row" colspan="4" class="border-0 text-end">
                        Total ({{account.currency}})
                      </th>
                      <td class="border-0 text-end">
                        <h4 class="m-0">{{formatMoney(infoPage.sumaIntereses)}}</h4>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              </div>
            </b-collapse>
          </div>

            <div class="py-2">
             

             
              <div class="d-print-none mt-4">
                <div class="float-end">
                  <!--<a
                    href="javascript:window.print()"
                    class="btn btn-success me-1"
                  >
                    <i class="fa fa-print"></i>
                  </a>-->
                  <a @click="routeGo('/cuentas/list')" class="btn btn-primary w-md ms-1">Regresar</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
